/* Reset and general styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff9c4;
  font-family: "Permanent Marker", cursive;
  text-align: center;
  overflow-y: auto;
}

/* Header styles */
#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff9c4;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

#main-header nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-header nav ul li {
  margin: 0 10px;
}

#main-header nav ul li a {
  color: #6b8e23;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

#main-header nav ul li a i {
  margin-right: 5px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6b8e23;
  cursor: pointer;
}

/* Content wrapper styles */
#content-wrapper {
  margin-top: 60px;
}

/* Overlay styles */
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

/* Countdown styles */
#countdown-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff9c4;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  animation: hover 2s infinite alternate;
  text-align: center;
  width: 80%;
  max-width: 600px;
  z-index: 100;
}

@keyframes hover {
  0% {
    transform: translate(-50%, -50%) translateY(0);
  }

  100% {
    transform: translate(-50%, -50%) translateY(-20px);
  }
}

#countdown {
  font-size: 4rem;
  color: #ffd700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.mystery-text {
  font-size: 2rem;
  color: #6b8e23;
  margin-top: 1rem;
}

.birthday-message {
  font-size: 5rem;
  color: #ffd700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

/* Pineapple styles */
.pineapple {
  position: fixed;
  width: 80px;
  height: 80px;
  background-image: url("../public/img/pineapple.png");
  background-size: cover;
  animation: falling 10s linear infinite;
  z-index: 200;
}

@keyframes falling {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100vh);
  }
}

.pineapple:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.pineapple.popped {
  animation: none;
  transform: translateY(0) rotate(0deg) scale(1);
  animation: popPineapple 0.5s forwards;
}

@keyframes popPineapple {
  0% {
    transform: scale(1) rotate(0deg);
    filter: brightness(1);
  }

  50% {
    transform: scale(1.2) rotate(180deg);
    filter: brightness(1.5) hue-rotate(-30deg);
  }

  100% {
    transform: scale(0) rotate(360deg);
    filter: brightness(1.5) hue-rotate(-30deg);
    opacity: 0;
  }
}

.pineapple.rainbow {
  animation: rainbowGlow 2s linear infinite, falling 10s linear infinite;
}

@keyframes rainbowGlow {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.pineapple.rainbow.popped {
  animation: popRainbowPineapple 0.5s forwards;
}

@keyframes popRainbowPineapple {
  0% {
    transform: scale(1) rotate(30deg);
    filter: hue-rotate(30deg);
    filter: brightness(1);
  }

  10% {
    transform: scale(1.2) rotate(60deg);
    filter: hue-rotate(60deg);
    filter: brightness(1);
  }

  20% {
    transform: scale(1.4) rotate(90deg);
    filter: hue-rotate(90deg);
    filter: brightness(1);
  }

  30% {
    transform: scale(1.6) rotate(120deg);
    filter: hue-rotate(120deg);
    filter: brightness(1);
  }

  40% {
    transform: scale(1.8) rotate(120deg);
    filter: hue-rotate(120deg);
    filter: brightness(1);
  }

  50% {
    transform: scale(2) rotate(180deg);
    filter: hue-rotate(180deg);
    filter: brightness(1.5);
  }

  60% {
    transform: scale(1.6) rotate(210deg);
    filter: hue-rotate(210deg);
    filter: brightness(1);
  }

  70% {
    transform: scale(1.3) rotate(240deg);
    filter: hue-rotate(240deg);
    filter: brightness(1);
  }

  80% {
    transform: scale(1) rotate(270deg);
    filter: hue-rotate(270deg);
    filter: brightness(1);
  }

  90% {
    transform: scale(0.5) rotate(300deg);
    filter: hue-rotate(300deg);
    filter: brightness(1);
  }

  100% {
    transform: scale(0) rotate(333deg);
    filter: hue-rotate(333deg);
    filter: brightness(1.5);
    opacity: 0;
  }
}


@keyframes rainbowGlow {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

/* Top controls styles */
#top-controls {
  display: flex;
  align-items: center;
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 500;
}

#volume-controls-box {
  background-color: #fff9c4;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

#volume-controls {
  display: flex;
  align-items: center;
}

#volume-controls i {
  margin: 0 5px;
  color: #6b8e23;
  cursor: pointer;
}

#volume-controls input[type="range"] {
  width: 100%;
  margin: 0 10px;
  /* Add margin to create space for volume icons */
}

#volume-controls .volume-icon {
  font-size: 1rem;
  color: #6b8e23;
}

#settings-container {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #6b8e23;
  cursor: pointer;
  margin-left: 20px;
}

.settings-icon {
  margin-right: 5px;
}

/* Pineapple counter styles */
#pineapple-counter-box {
  position: fixed;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff9c4;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 150;

}

#pineapple-counter {
  font-size: 1.5rem;
  color: #6b8e23;
}

/* Test countdown button styles */
#test-countdown {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  font-size: 1.5rem;
  background-color: #6b8e23;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* Award page styles */
.awards-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff9c4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.awards-container h1 {
  text-align: center;
  color: #6b8e23;
  margin-bottom: 20px;
}

.awards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-items: center;
}

.award {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5e5b5;
  border-radius: 10px;
  transition: opacity 0.3s;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.award.unlocked {
  opacity: 1;
}

.award-tooltip {
  position: relative;
  cursor: pointer;
}

.award-tooltip .tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
}

.award.animated {
  transform: rotateY(360deg);
  transition: transform 2s;
}

.badge {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid #fff;
}

.leaderboard-item .badge {
  width: 50px;
  height: 50px;
  font-size: 1em;
  margin-left: 20px;
}

.badge.shiny {
  animation: shiny-effect 2s linear infinite;
}

@keyframes shiny-effect {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }

  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }

  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
}

.award .badge:hover {
  transform: scale(1.1) rotateX(30deg);
}

.award .badge-icon {
  position: relative;
  z-index: 1;
  font-size: 24px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.award p {
  text-align: center;
  color: #6b8e23;
  font-size: 14px;
}


@keyframes shinyEffect {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }

  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }

  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
}

.badge.milestone-10000 {
  animation: shiny-effect-red 2s linear infinite;
  font-size: 0.7rem;
}

.badge.milestone-20000 {
  animation: shiny-effect-red 2s linear infinite;
  font-size: 0.7rem;
}

.badge.milestone-30000 {
  animation: shiny-effect-teal 2s linear infinite;
  font-size: 0.7rem;
}

.badge.milestone-50000 {
  animation: shiny-effect-black 2s linear infinite;
  font-size: 0.7rem;
}

@keyframes shiny-effect-red {
  0% {
    box-shadow: 0 0 10px rgba(255, 107, 107, 0.8);
  }

  50% {
    box-shadow: 0 0 20px rgba(255, 107, 107, 0.8);
  }

  100% {
    box-shadow: 0 0 10px rgba(255, 107, 107, 0.8);
  }
}

@keyframes shiny-effect-teal {
  0% {
    box-shadow: 0 0 10px rgba(78, 205, 196, 0.8);
  }

  50% {
    box-shadow: 0 0 20px rgba(78, 205, 196, 0.8);
  }

  100% {
    box-shadow: 0 0 10px rgba(78, 205, 196, 0.8);
  }
}

@keyframes shiny-effect-black {
  0% {
    box-shadow: 0 0 10px rgba(49, 49, 49, 0.8);
  }

  50% {
    box-shadow: 0 0 20px rgba(49, 49, 49, 0.8);
  }

  100% {
    box-shadow: 0 0 10px rgba(49, 49, 49, 0.8);
  }
}

.login-form,
.registration-form,
.profile-page {
  max-width: 400px;
  margin: 100px auto 0;
  padding: 20px;
  background-color: #fff9c4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.profile-page h2,
.registration-form h2,
.login-form h2 {
  color: #6b8e23;
  margin-bottom: 20px;
}

.profile-page label,
.registration-form label,
.login-form label {
  display: block;
  color: #6b8e23;
  margin-bottom: 10px;
}

.profile-page input[type='password'],
.profile-page input[type='file'],
.registration-form input[type='text'],
.registration-form input[type='password'],
.login-form input[type='text'],
.login-form input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #6b8e23;
  border-radius: 5px;
  margin-bottom: 20px;
}

.profile-page button,
.registration-form button,
.login-form button {
  background-color: #6b8e23;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-page #settings-button {
  margin-left: 0;
}

.profile-page img {
  width: 100px;
  height: 100px;
}

.logout-button {
  background-color: #6b8e23;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Dark mode toggle styles */
.dark-mode-toggle {
  margin-right: 20px;
}

.dark-mode-toggle label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.dark-mode-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dark-mode-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.dark-mode-toggle .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.dark-mode-toggle input:checked+.slider {
  background-color: #6b8e23;
}

.dark-mode-toggle input:checked+.slider:before {
  transform: translateX(26px);
}

/* Profile link styles */
.profile-link {
  margin-left: auto;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-left {
  margin-right: auto;
}

.header-right {
  margin-left: auto;
}

#main-header nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.dark-mode-toggle {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.dark-mode-text {
  margin-left: 10px;
  color: #6b8e23;
  font-size: 1.2rem;
}


.profile-link {
  margin-left: auto;
}

.profile-link a {
  color: #6b8e23;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.profile-link a i {
  margin-right: 5px;
}

.dark-mode-text {
  margin-left: 10px;
  color: #6b8e23;
  font-size: 1.2rem;
}

body.dark-mode .dark-mode-text {
  color: #ffd700;
}


/* Dark mode styles */
body.dark-mode {
  background-color: #333;
  color: #fff;
}

body.dark-mode #main-header,
body.dark-mode #countdown-box,
body.dark-mode #volume-controls-box,
body.dark-mode #pineapple-counter-box,
body.dark-mode .settings-container,
body.dark-mode .leaderboard-container {
  background-color: #222;
  color: #fff;
}

body.dark-mode #countdown,
body.dark-mode #pineapple-counter,
body.dark-mode #settings-container,
body.dark-mode #volume-controls i,
body.dark-mode .leaderboard-container h1,
body.dark-mode .leaderboard-item .rank,
body.dark-mode .leaderboard-item .player-name,
body.dark-mode .awards-container h1,
body.dark-mode #main-header nav ul li a {
  color: #ffd700;
  text-shadow: none;
}

body.dark-mode #main-header nav.active ul {
  background-color: #222;
}

body.dark-mode #main-header nav.active ul li a {
  color: #ffd700;
}

body.dark-mode .mystery-text {
  color: #9acd32;
}

body.dark-mode .pineapple {
  background-image: url('../public/img/pineapple.png');
}

body.dark-mode .leaderboard-item {
  background-color: #333;
}

body.dark-mode .leaderboard-item .pop-count {
  color: #ff6b6b;
}

body.dark-mode #settings-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

body.dark-mode .settings-container {
  background-color: #222;
}

body.dark-mode .settings-container h2 {
  color: #ffd700;
}

body.dark-mode .settings-container label {
  color: #fff;
}

body.dark-mode .settings-container button {
  background-color: #ffd700;
  color: #222;
}

body.dark-mode #settings-button {
  color: #ffd700;
}

body.dark-mode .menu-toggle {
  color: #ffd700;
}

body.dark-mode .toggle-switch label {
  background-color: #666;
}

body.dark-mode .toggle-switch label::before {
  background-color: #ccc;
}

body.dark-mode .work-in-progress {
  background-color: #333;
  color: #ffd700;
}

body.dark-mode .awards-container {
  background-color: #222;
}

body.dark-mode .award {
  background-color: #333333;
}

body.dark-mode .award p {
  color: #fff;
}

body.dark-mode .award .badge {
  border-color: #fff;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1), 0 2px 4px rgba(255, 255, 255, 0.06);
}

body.dark-mode .award.unlocked.shiny {
  animation: shinyEffect 2s linear infinite;
}

body.dark-mode .profile-page,
body.dark-mode .registration-form,
body.dark-mode .login-form {
  background-color: #222;
}

body.dark-mode .profile-page h2,
body.dark-mode .registration-form h2,
body.dark-mode .login-form h2,
body.dark-mode .profile-page label,
body.dark-mode .registration-form label,
body.dark-mode .login-form label {
  color: #fff;
}

body.dark-mode .profile-page input[type='password'],
body.dark-mode .profile-page input[type='file'],
body.dark-mode .registration-form input[type='text'],
body.dark-mode .registration-form input[type='password'],
body.dark-mode .login-form input[type='text'],
body.dark-mode .login-form input[type='password'] {
  background-color: #333;
  color: #fff;
  border-color: #fff;
}

body.dark-mode .profile-page button,
body.dark-mode .registration-form button,
body.dark-mode .login-form button {
  background-color: #ffd700;
  color: #222;
}

body.logout-button.dark-mode {
  background-color: #ffd700;
  color: #222;
}

body.profile-page.dark-mode {
  background-color: #222;
  color: #fff;
}

body.profile-page.dark-mode h2,
body.profile-page.dark-mode h3,
body.profile-page.dark-mode p {
  color: #fff;
}

/* Settings overlay styles */
#settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#settings-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6b8e23;
  cursor: pointer;
  margin-left: 1em;
}

.settings-container {
  background-color: #fff9c4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background-color: #6b8e23;
  border: none;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.setting {
  margin-bottom: 20px;
}

.setting label {
  display: block;
  margin-bottom: 10px;
  color: #6b8e23;
}

.setting input[type='range'] {
  width: 100%;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-switch label::before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch input:checked+label {
  background-color: #6b8e23;
}

.toggle-switch input:checked+label::before {
  transform: translateX(26px);
}

#music-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.overlay-content {
  text-align: center;
  color: #fff;
}

/* Work in Progres styles*/
.work-in-progress {
  background-color: #ffd700;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  align-self: center;
}

.work-in-progress h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.work-in-progress p {
  font-size: 1.5rem;
}

/* Leaderboard styles */
.leaderboard-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff9c4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.leaderboard-container h1 {
  text-align: center;
  color: #6b8e23;
  margin-bottom: 20px;
}

.leaderboard-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5e5b5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.leaderboard-item .rank {
  font-size: 1.5rem;
  font-weight: bold;
  color: #6b8e23;
  margin-right: 10px;
}

.leaderboard-item .player-name {
  flex-grow: 1;
  color: #6b8e23;
}

.leaderboard-item .pop-count {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff6b6b;
}

.leaderboard-item .player-name {
  display: flex;
  align-items: center;
}

.leaderboard-item .award {
  margin-left: 2ch;
  padding: 0px;
}

.leaderboard-item .award .badge {
  width: 50px;
  height: 50px;
}

.leaderboard-item .leaderboard-item .award .badge-icon {
  font-size: 1rem;
}


.rank {
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b8e23;
  margin-right: 10px;
}

.player-name {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the player's name */
}

.pop-count {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff6b6b;
  justify-self: end;
}



/* Mobile styles */
@media screen and (max-width: 524px) {
  body {
    height: 100vh;
  }

  #main-header nav ul {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  #main-header nav.active ul {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff9c4;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }

  #main-header nav.active ul li {
    margin: 10px 0;
  }

  .menu-toggle {
    display: block;
  }

  #content-wrapper {
    margin-top: 100px;
  }

  #countdown {
    font-size: 3rem;
  }

  .mystery-text {
    font-size: 1.5rem;
  }

  .birthday-message {
    font-size: 4rem;
  }

  #top-controls {
    top: 80px;
    right: 0;
    left: 0;
    justify-content: center;
  }

  #volume-controls-box {
    margin-left: 0;
  }

  #pineapple-counter-box {
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%;
  }

  .leaderboard-container {
    margin-top: 40px;
  }

  .login-form,
  .registration-form,
  .profile-page {
    margin-top: 120px;
    max-width: 90%;
  }

  #main-header nav.active ul {
    display: flex;
    flex-direction: column;
  }

  .dark-mode-toggle {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile-link {
    margin-left: 0;
    margin-top: auto;
  }

  .header-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-left {
    margin-right: 0;
  }

  .header-right {
    margin-left: 0;
  }

  .menu-toggle {
    display: block;
    margin-right: 10px;
  }
}



@media screen and (max-height: 900px) {
  #countdown-box {
    top: 60%;
  }

  #pineapple-counter-box {
    top: 20%;
  }

}

@media screen and (max-height: 700px) {
  #countdown-box {
    top: 70%;
  }

  #pineapple-counter-box {
    top: 18%;
  }
}
